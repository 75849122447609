<template>
    <MenuMange menuType="1"/>
</template>

<script>
import MenuMange from '@/components/menuManage'
export default {
  components:{
    MenuMange
  }
}
</script>

<style>

</style>